.wrapper {
	position: relative;
	top: 0;
	height: calc(100vh - 200px);
}

body, .wrapper {
	min-height:  calc(100vh - 200px);
}

footer {
    padding: 10px 50px;
    text-align: right;
}

.clCityCard {
    min-width: 200px;
    max-width: 250px;
}

.clCityCard-body {
    text-align: left;
}

#idAddWarning {
    display: block;
    color: crimson;
}

#idSideBar {
    /* width: 250px; */
    overflow: hidden;
}

#idAddBtn {
    font-size: 1.2em;
}

.modal-dialog {
    margin-top: 20vh;
    margin-bottom: 20w;
} 

.modal-header {
    height: 50px;
}

.modal-body label {
width: 100%;
}

form .modal-footer {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 0;
}

